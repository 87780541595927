<template>
  <div
    class="relative isolate flex aspect-square min-w-[152px] max-w-[380px] items-center justify-center
    before:absolute before:w-full before:animate-heart-beat before:rounded-full before:bg-green before:pt-[100%]"
  >
    <div class="relative h-fit max-h-[calc(100%_/_1.41421356237)] w-fit max-w-[calc(100%_/_1.41421356237)]">
      <slot />
    </div>
  </div>
</template>
